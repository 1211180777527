import React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby';
    
import Section from "@components/Section";
import SEO from "@components/SEO";
import Layout from "@components/Layout";
import Headings from '@components/Headings';
import HorizontalRule from '@components/HorizontalRule';

const Error404:React.FC<{}> = () => {

  return (
    <Layout>
      <SEO pathname='/404' title="Error 404" />
      <Section narrow style={{zIndex: `1`, position: `relative`}}>
        <Msg>
            <Headings.h1>Page not found</Headings.h1>
            <HorizontalRule />
            <ReturnHomeButton to="/">Go back home</ReturnHomeButton>
        </Msg>
      </Section>
      <Gradient />
    </Layout>
  );
};

export default Error404;

const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${p => p.theme.colors.gradient};
  transition: ${p => p.theme.colorModeTransition};
`;

const Msg = styled.div`
    text-align: center;
    margin-top: 6em;
`;

const ReturnHomeButton = styled(Link)`
  background-image: linear-gradient(to right, rgb(121, 0, 255) 0%, rgb(255, 88, 202)  51%, rgb(121, 0, 255)  100%);
  margin-top: 1em;
  
  padding: 1em 2em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  border-radius: 10px;
  border: 2px solid ${p => p.theme.colors.background};
  box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);


  &:hover, &:active {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`;